/// <reference path="../groupthink-js.d.ts" />
import { useMemo } from 'react';
import { AxiosRequestConfig } from 'axios';
import { useUser } from './user';
import { axios, fetcher, apiRequest } from '../lib';
import useSWR, { KeyedMutator } from 'swr';
import _ from 'underscore';
import dayjs from 'dayjs';
import isBetween from 'dayjs/plugin/isBetween';
import isToday from 'dayjs/plugin/isToday';
import isTomorrow from 'dayjs/plugin/isTomorrow';
import relativeTime from 'dayjs/plugin/relativeTime';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';

dayjs.extend(relativeTime);
dayjs.extend(isBetween);
dayjs.extend(isToday);
dayjs.extend(isTomorrow);
dayjs.extend(utc);
dayjs.extend(timezone);

export const useUserAgendas = (search?: string | null) => {
  let url = `/v1/agendas`;

  if (search) {
    url += `?filter[search]=${search}`;
  }

  const { data, error, isLoading, mutate } = useSWR<
    Groupthink.SuccessfulResponseContent<'agenda.index'>
  >(url, fetcher, {
    refreshWhenHidden: true,
    refreshWhenOffline: true,
    refreshInterval: 1000 * 60 * 60, // 1 hour
  });

  return {
    agendas: data?.data,
    isLoading,
    isError: error,
    mutate,
  };
};

export const useUserAgendaItems = () => {
  const { data, error, isLoading, mutate } = useSWR<
    Groupthink.SuccessfulResponseContent<'agenda.userItems'>
  >(`/v1/agendas/items`, fetcher, {
    keepPreviousData: true,
  });

  return {
    items: data?.data,
    isLoading,
    isError: error,
    mutate,
  };
};

export const useAgenda = (
  id?: string,
  options?: {
    squashed?: boolean;
    useRealtimeResource?: (eventName, channel, mutate, url) => void;
  }
) => {
  let url = `/v1/agendas/${id}`;
  const { squashed = true, useRealtimeResource } = options || {};
  if (squashed) {
    url += '?squashed=true';
  }

  const {
    data: agenda,
    error,
    isLoading,
    mutate,
  } = useSWR<Groupthink.SuccessfulResponseContent<'agenda.show'>>(
    () => (id ? url : false),
    fetcher
  );

  useRealtimeResource?.('.AgendaUpdated', !id ? null : `App.Models.Agenda.${id}`, mutate, url);

  const createAgenda = <RouteName = 'agenda.store'>({
    setErrors,
    setIsCreating,
    onSuccess,
    payload,
  }: Groupthink.CreateOperationOptions<RouteName>) =>
    apiRequest<RouteName>(`/v1/agendas`, mutate, 'POST', {
      setErrors,
      setLoading: setIsCreating,
      payload,
      onSuccess,
    });

  const updateAgenda = <RouteName = 'agenda.update'>({
    setErrors,
    setIsUpdating,
    onSuccess,
    payload,
  }: Groupthink.UpdateOperationOptions<RouteName>) =>
    apiRequest<RouteName>(url, mutate, 'PUT', {
      setErrors,
      setLoading: setIsUpdating,
      payload,
      onSuccess,
    });

  const deleteAgenda = async <RouteName = 'agenda.destroy'>({
    setErrors,
    setIsDeleting,
    onSuccess,
  }: Groupthink.DeleteOperationOptions<RouteName>) =>
    apiRequest<RouteName>(url, mutate, 'DELETE', {
      setErrors,
      setLoading: setIsDeleting,
      onSuccess,
    });

  const inviteUser = async <RouteName = 'agenda.addUser'>({
    setErrors,
    setIsInviting,
    onSuccess,
    payload,
  }: Groupthink.BaseOperationOptions<RouteName> & {
    setIsInviting?: (isInviting: boolean) => void;
  }) =>
    apiRequest<RouteName>(`/v1/agendas/${id}/users`, mutate, 'POST', {
      setErrors,
      setLoading: setIsInviting,
      payload,
      onSuccess,
    });

  const updateUser = async <RouteName = 'agenda.updateUser'>(
    userId: string,
    { setErrors, setIsUpdating, onSuccess, payload }: Groupthink.UpdateOperationOptions<RouteName>
  ) =>
    apiRequest<RouteName>(`/v1/agendas/${id}/users/${userId}`, mutate, 'PUT', {
      setErrors,
      setLoading: setIsUpdating,
      payload,
      onSuccess,
    });

  const removeUser = async <RouteName = 'agenda.removeUser'>(
    userId: string,
    {
      setErrors,
      setIsRemoving,
      onSuccess,
    }: Omit<Groupthink.BaseOperationOptions<RouteName>, 'payload'> & {
      setIsRemoving?: (isRemoving: boolean) => void;
    }
  ) =>
    apiRequest<RouteName>(`/v1/agendas/${id}/users/${userId}`, mutate, 'DELETE', {
      setErrors,
      setLoading: setIsRemoving,
      onSuccess,
    });

  const revokeInvitation = async <RouteName = 'agenda.revokeInvitation'>(
    invitationId: string,
    {
      setErrors,
      setIsRevoking,
      onSuccess,
    }: Omit<Groupthink.BaseOperationOptions<RouteName>, 'payload'> & {
      setIsRevoking?: (isRevoking: boolean) => void;
    }
  ) =>
    apiRequest<RouteName>(`/v1/agendas/${id}/invitations/${invitationId}`, mutate, 'DELETE', {
      setErrors,
      setLoading: setIsRevoking,
      onSuccess,
    });

  const subscribe = async <RouteName = 'agenda.subscribe'>({
    setErrors,
    setIsSubscribing,
    onSuccess,
    payload,
  }: Groupthink.BaseOperationOptions<RouteName> & {
    setIsSubscribing?: (isSubscribing: boolean) => void;
  }) =>
    apiRequest<RouteName>(`/v1/agendas/${id}/subscribe`, mutate, 'POST', {
      setErrors,
      setLoading: setIsSubscribing,
      payload,
      onSuccess,
    });

  const unsubscribe = async <RouteName = 'agenda.unsubscribe'>({
    setErrors,
    setIsUnsubscribing,
    onSuccess,
    payload,
  }: Groupthink.BaseOperationOptions<RouteName> & {
    setIsUnsubscribing?: (isUnsubscribing: boolean) => void;
  }) =>
    apiRequest<RouteName>(`/v1/agendas/${id}/subscribe`, mutate, 'DELETE', {
      setErrors,
      setLoading: setIsUnsubscribing,
      payload,
      onSuccess,
    });

  const { user } = useUser('me');

  let isCurrentUserAgendaEditor = false;
  if (user && agenda?.data && agenda?.data?.users) {
    // is_editor is only available on UserResource
    // The agenda.show endpoint can return an AgendaResource or a BriefAgendaResource, but only AgendaResource will have a UserResource.
    const currentUser = _.indexBy(agenda.data.users, 'id')[user.id] as Groupthink.UserResource;
    isCurrentUserAgendaEditor = currentUser?.is_editor;
  }

  return {
    agenda: agenda?.data,
    isLoading,
    isError: error,
    mutate,
    createAgenda,
    updateAgenda,
    deleteAgenda,
    inviteUser,
    updateUser,
    removeUser,
    revokeInvitation,
    subscribe,
    unsubscribe,
    isCurrentUserAgendaEditor,
  };
};

export const useAgendas = (search?: string) => {
  let url = `/v1/agendas`;

  if (search) {
    url += `?filter[search]=${search}`;
  }

  const {
    data: agenda,
    error,
    isLoading,
    mutate: mutateList,
  } = useSWR<Groupthink.SuccessfulResponseContent<'agenda.index'>>(url, fetcher, {
    keepPreviousData: true,
  });

  const createAgenda = <RouteName = 'agenda.store'>({
    setErrors,
    setIsCreating,
    onSuccess,
    payload,
  }: Groupthink.CreateOperationOptions<RouteName>) =>
    apiRequest<RouteName>(`/v1/agendas`, mutateList, 'POST', {
      setErrors,
      setLoading: setIsCreating,
      payload,
      onSuccess,
    });

  const updateAgenda = <RouteName = 'agenda.update'>(
    id: string,
    { setErrors, setIsUpdating, onSuccess, payload }: Groupthink.UpdateOperationOptions<RouteName>
  ) =>
    apiRequest<RouteName>(`/v1/agendas/${id}`, mutateList, 'PUT', {
      setErrors,
      setLoading: setIsUpdating,
      payload,
      onSuccess,
    });

  const deleteAgenda = async <RouteName = 'agenda.destroy'>(
    agendaId: string,
    { setErrors, setIsDeleting, onSuccess }: Groupthink.DeleteOperationOptions<RouteName>
  ) =>
    apiRequest<RouteName>(`/v1/agendas/${agendaId}`, mutateList, 'DELETE', {
      setErrors,
      setLoading: setIsDeleting,
      onSuccess,
    });

  return {
    agendas: agenda?.data,
    isLoading,
    isError: error,
    mutateList,
    createAgenda,
    updateAgenda,
    deleteAgenda,
  };
};

export const createAgenda = <RouteName = 'agenda.store'>(
  mutate: KeyedMutator<Groupthink.SuccessfulResponseContentData<'agenda.userIndex'>>,
  { setErrors, setIsCreating, onSuccess, payload }: Groupthink.CreateOperationOptions<RouteName>
) =>
  apiRequest<RouteName>(`/v1/agendas`, mutate, 'POST', {
    setErrors,
    setLoading: setIsCreating,
    payload,
    onSuccess,
  });

export const updateAgenda = <RouteName = 'agenda.update'>(
  id: string,
  mutate: KeyedMutator<Groupthink.SuccessfulResponseContentData<'agenda.userIndex'>>,
  { setErrors, setIsUpdating, onSuccess, payload }: Groupthink.UpdateOperationOptions<RouteName>
) =>
  apiRequest<RouteName>(`/v1/agendas/${id}`, mutate, 'PUT', {
    setErrors,
    setLoading: setIsUpdating,
    payload,
    onSuccess,
  });

export const manipulateAgendas = async <RouteName>(
  url: string,
  {
    method = 'POST',
    payload,
  }: {
    method?: AxiosRequestConfig['method'];
    payload?: Groupthink.RequestPayload<RouteName> | Record<string, unknown>;
  }
) => {
  await axios(url, {
    method,
    data: payload,
  });
};

export const useAgendaUsers = (agendaId: string) => {
  const { agenda } = useAgenda(agendaId);

  const users = useMemo(
    () => (agenda?.users ? _.indexBy(agenda?.users, 'id') : []),
    [agenda?.users]
  );

  return { users };
};

export const useActiveMeetings = () => {
  const { agendas, isLoading } = useUserAgendas();

  const activeMeetings = agendas
    ?.filter((agenda) => agenda?.active_meeting?.starts_at || agenda?.active_meeting?.ends_at)
    .map((agenda) => agenda.active_meeting);

  return { activeMeetings, isLoading };
};

/**
 * Given an agenda, return the next meeting time in a human-readable format.
 *
 * time_string - The human-readable time string
 * meeting_imminent - When true, the meeting is imminent (starts within 10 minutes)
 * meeting_is_scheduled - When true, the meeting is scheduled and the start time is known
 * meeting_has_no_upcoming_event - When true, the agenda is not attached to an event
 */
export const selectNextMeetingTime = (agenda: Groupthink.AgendaResource) => {
  const meeting_is_scheduled = agenda?.next_meeting?.starts_at;
  const meeting_has_no_upcoming_event = agenda && !agenda?.attached_to_event;

  let time_string = '';
  let meeting_imminent = false;
  if (agenda?.next_meeting) {
    const starts = dayjs(agenda?.next_meeting?.starts_at);
    time_string = starts.format('dddd MMM D @ h:mm A');

    if (
      starts.day() === dayjs().day() &&
      dayjs().isBefore(starts) &&
      starts.isBefore(dayjs().add(1, 'day'))
    ) {
      time_string = 'Today at ' + starts.format('h:mm A');
    }
    if (starts.day() === dayjs().add(1, 'day').day()) {
      time_string = 'Tomorrow at ' + starts.format('h:mm A');
    }

    if (starts.diff(dayjs(), 'minute') <= 10) {
      meeting_imminent = true;
    }
  }
  return { time_string, meeting_imminent, meeting_is_scheduled, meeting_has_no_upcoming_event };
};

export const useAgendaObservations = (agendaId: string) => {
  const url = `/v1/agendas/${agendaId}/observations`;

  const {
    data: observations,
    error,
    isLoading,
    mutate: mutateObservations,
  } = useSWR(url, fetcher, {
    keepPreviousData: true,
  });

  return {
    observations: observations?.data,
    isLoading,
    isError: error,
    mutateObservations,
  };
};

export const selectAgendasGroupedByTiming = (agendas: Groupthink.AgendaResource[]) => {
  if (!agendas) return {};

  return _.groupBy(agendas, (agenda) => {
    if (agenda.active_meeting) {
      // Active meetings should always show up under Today
      return 'Today';
    }

    if (agenda.next_meeting || agenda.attached_event) {
      const start_time = agenda.next_meeting?.starts_at || agenda.attached_event?.starts_at;
      const starts = dayjs(start_time).tz(Intl.DateTimeFormat().resolvedOptions().timeZone);

      if (starts.isToday()) {
        return 'Today';
      }

      if (starts.isTomorrow()) {
        return 'Tomorrow';
      }

      if (!agenda.next_meeting) {
        return 'Past';
      }

      // Agendas with a next_meeting or attached_event should never be displayed in Drafts
      return 'Upcoming';
    }

    return 'Draft';
  });
};

export const selectRecurringAgendas = (agendas: Groupthink.AgendaResource[]) => {
  return agendas?.filter((agenda) => Boolean(agenda?.next_meeting?.recurring_vendor_id)) ?? [];
};

export const selectAgendaHost = (agenda: Groupthink.AgendaResource) => {
  return agenda?.users?.find((user) => user.role === 'host');
};
