/// <reference path="../groupthink-js.d.ts" />

import { fetcher, apiRequest } from '../lib';
import useSWR from 'swr';

export const useCalendar = (workspaceId?: string, calendarId?: string) => {
  const {
    data: calendar,
    error,
    isLoading,
    mutate,
  } = useSWR<Groupthink.SuccessfulResponseContent<'calendar.show'>>(
    () =>
      Boolean(workspaceId) && Boolean(calendarId)
        ? `/v1/workspaces/${workspaceId}/calendars/${calendarId}`
        : false,
    fetcher,
    {
      keepPreviousData: true,
    }
  );

  const updateCalendar = async <RouteName = 'calendar.update'>({
    setErrors,
    setIsUpdating,
    onSuccess,
    payload,
  }: Groupthink.UpdateOperationOptions<RouteName>) => {
    await apiRequest<RouteName>(
      `/v1/workspaces/${workspaceId}/calendars/${calendarId}`,
      mutate,
      'PUT',
      {
        setErrors,
        setLoading: setIsUpdating,
        payload,
        onSuccess,
      }
    );
  };

  const syncCalendar = async <RouteName = 'calendar.sync'>({
    setErrors,
    setIsSyncing,
    onSuccess,
  }: Omit<Groupthink.BaseOperationOptions<RouteName>, 'payload'> & {
    setIsSyncing?: (setIsSyncing: boolean) => void;
  }) =>
    apiRequest<RouteName>(
      `/v1/workspaces/${workspaceId}/calendars/${calendarId}/sync`,
      mutate,
      'POST',
      {
        setErrors,
        setLoading: setIsSyncing,
        onSuccess,
      }
    );

  return {
    calendar: calendar?.data,
    isLoading,
    isError: error,
    mutate,
    updateCalendar,
    syncCalendar,
    hasConnectedCalendar:
      calendar &&
      calendar.data &&
      calendar.data.vendor_id &&
      calendar.data.connected_calendars &&
      calendar.data.connected_calendars.length > 0,
  };
};

export const useCalendarEvents = (workspaceId: string, calendarId: string, start_date: string) => {
  let url =
    Boolean(workspaceId) && Boolean(calendarId)
      ? `/v1/workspaces/${workspaceId}/calendars/${calendarId}/events`
      : false;

  if (url && Boolean(start_date)) {
    url += `?filter[starts_at]=${start_date}`;
  }

  const {
    data: events,
    error,
    isLoading,
    mutate: mutateList,
  } = useSWR(() => url, fetcher, {
    keepPreviousData: true,
  });

  const addAgenda = <RouteName = 'event.attach'>(
    eventId: string,
    {
      setErrors,
      setIsAdding,
      onSuccess,
      payload,
    }: Groupthink.BaseOperationOptions<RouteName> & {
      setIsAdding?: (isAdding: boolean) => void;
    }
  ) =>
    apiRequest<RouteName>(
      `/v1/workspaces/${workspaceId}/calendars/${calendarId}/events/${eventId}`,
      mutateList,
      'POST',
      {
        setErrors,
        setLoading: setIsAdding,
        payload,
        onSuccess,
      }
    );

  const createEvent = ({
    setErrors,
    setIsCreating,
    onSuccess,
    payload,
  }: {
    setErrors?: Groupthink.ErrorsCallback;
    setIsCreating?: (isCreating: boolean) => void;
    onSuccess?: Groupthink.OnSuccessResponseDataCallback<'event.index'>;
    payload?: Groupthink.RequestPayload<'event.index'>;
  } = {}) =>
    apiRequest(
      `/v1/workspaces/${workspaceId}/calendars/${calendarId}/events/`,
      mutateList,
      'POST',
      {
        setErrors,
        setLoading: setIsCreating,
        payload,
        onSuccess,
      }
    );

  const updateEvent = <RouteName = 'event.update'>(
    eventId: string,
    {
      setErrors,
      setIsUpdating,
      onSuccess,
      payload,
    }: {
      setErrors?: Groupthink.ErrorsCallback;
      setIsUpdating?: (isUpdating: boolean) => void;
      onSuccess?: Groupthink.OnSuccessResponseDataCallback<RouteName>;
      payload?: Groupthink.RequestPayload<RouteName>;
    } = {}
  ) =>
    apiRequest<RouteName>(
      `/v1/workspaces/${workspaceId}/calendars/${calendarId}/events/${eventId}`,
      mutateList,
      'PUT',
      {
        setErrors,
        setLoading: setIsUpdating,
        payload,
        onSuccess,
      }
    );

  return {
    events: events?.data,
    isLoading,
    isError: error,
    mutateList,
    addAgenda,
    createEvent,
    updateEvent,
  };
};

export const useUserCalendarEvents = (start_date: string) => {
  const {
    data,
    error,
    isLoading,
    mutate: mutateList,
  } = useSWR<Groupthink.SuccessfulResponseContent<'event.useCalendarEvents'>>(
    `/v1/calendar_events?filter[starts_at]=${start_date}`,
    fetcher,
    {
      keepPreviousData: true,
      errorRetryCount: 1,
    }
  );

  // TODO: Fix these TypeScript errors
  // @ts-ignore
  const events = Array.isArray(data?.data) ? data?.data : data?.data?.events;
  // @ts-ignore
  const { connection_errors } = data?.data ?? {};

  const addAgenda = <RouteName = 'event.attach'>(
    eventId: string,
    calendarId: string,
    workspaceId: string,
    {
      setErrors,
      setIsAdding,
      onSuccess,
      payload,
    }: Groupthink.BaseOperationOptions<RouteName> & {
      setIsAdding?: (isAdding: boolean) => void;
    }
  ) =>
    apiRequest<RouteName>(
      `/v1/workspaces/${workspaceId}/calendars/${calendarId}/events/${eventId}`,
      mutateList,
      'POST',
      {
        setErrors,
        setLoading: setIsAdding,
        payload,
        onSuccess,
      }
    );

  return {
    events,
    connectionErrors: connection_errors,
    isLoading,
    isError: error,
    mutateList,
    addAgenda,
  };
};
