/// <reference path="../groupthink-js.d.ts" />

import { axios, apiRequest, fetcher } from '../lib';
import useSWR, { KeyedMutator } from 'swr';

export const useMessage = (
  agendaId?: string,
  threadId?: string,
  id?: string,
  options?: {
    useRealtimeMutate?: (eventName: string, channel: string | null, url: string | null) => void;
  }
) => {
  const { useRealtimeMutate } = options || {};

  let url = `/v1/agendas/${agendaId}/threads/${threadId}/messages/${id}`;

  // if the agendaId starts with the letters "RM" we need a different url
  if (agendaId?.startsWith('RM')) {
    url = `/v1/rooms/${agendaId}/threads/${threadId}/messages/${id}`;
  }
  console.log(url);
  const {
    data: message,
    error,
    isLoading,
    isValidating,
    mutate,
  } = useSWR(() => (Boolean(agendaId) && Boolean(threadId) && Boolean(id) ? url : false), fetcher);

  useRealtimeMutate?.('MessageSent', !threadId ? null : `threads.${threadId}.messages`, url);

  const updateMessage = <RouteName = 'message.update'>({
    setErrors,
    setIsUpdating,
    onSuccess,
    payload,
  }: Groupthink.UpdateOperationOptions<RouteName>) =>
    apiRequest<RouteName>(url, mutate, 'PUT', {
      setErrors,
      setLoading: setIsUpdating,
      payload,
      onSuccess,
    });

  const deleteMessage = async <RouteName = 'message.destroy'>({
    setErrors,
    setIsDeleting,
    onSuccess,
  }: Groupthink.DeleteOperationOptions<RouteName>) =>
    apiRequest<RouteName>(url, mutate, 'DELETE', {
      setErrors,
      setLoading: setIsDeleting,
      onSuccess,
    });

  return {
    thread: message?.data,
    isLoading,
    isValidating,
    isError: error,
    mutate,
    updateMessage,
    deleteMessage,
  };
};

export const useMessageReactions = (
  agendaId?: string,
  threadId?: string,
  id?: string,
  options?: {
    useRealtimeMutate?: (eventName: string, channel: string | null, url: string | null) => void;
  }
) => {
  const { useRealtimeMutate } = options || {};

  // If we're missing a url param, we're likely waiting on another request. To avoid violating the rules of hooks, we just use false for the key.
  let url =
    Boolean(agendaId) && Boolean(threadId) && Boolean(id)
      ? `/v1/agendas/${agendaId}/threads/${threadId}/messages/${id}/reactions`
      : false;

  // if the agendaId starts with the letters "RM" we need a different url
  if (url && agendaId?.startsWith('RM')) {
    url = `/v1/rooms/${agendaId}/threads/${threadId}/messages/${id}/reactions`;
  }

  console.log(url);
  // necessary to avoid TypeScript complaining about this being potentially false.
  const key = url as string;

  const {
    data: message,
    error,
    isLoading,
    isValidating,
    mutate,
  } = useSWR<Groupthink.SuccessfulResponseContent<'message.reactions'>>(url, fetcher);

  useRealtimeMutate?.('MessageReactedTo', threadId ? `threads.${threadId}.messages` : null, key);

  const reactToMessage = <RouteName = 'message.react'>({
    payload,
  }: Groupthink.CreateOperationOptions<RouteName>) => {
    mutate(
      async () =>
        axios(key, {
          method: 'POST',
          data: payload,
        }).then((res) => res.data),
      {
        revalidate: false,
        populateCache: (updatedMessage) => {
          return updatedMessage;
        },
      }
    );
  };

  const unreactToMessage = <RouteName = 'message.react'>({
    payload,
  }: Groupthink.CreateOperationOptions<RouteName>) => {
    mutate(
      async () =>
        axios(key, {
          method: 'DELETE',
          data: payload,
        }).then((res) => res.data),
      {
        revalidate: false,
        populateCache: (updatedMessage) => {
          return updatedMessage;
        },
      }
    );
  };

  return {
    message: message?.data,
    reactions: message?.data?.reactions,
    isLoading,
    isValidating,
    isError: error,
    mutate,
    reactToMessage,
    unreactToMessage,
  };
};

export const useMessages = (
  agendaId?: string,
  threadId?: string,
  options?: {
    useRealtimeCollection?: (
      eventName: string,
      channel: string | null,
      mutate: KeyedMutator<any>,
      mutationType: 'create' | 'update' | 'delete',
      url: string | null
    ) => void;
  }
) => {
  const { useRealtimeCollection } = options || {};

  let url =
    // agendaId && threadId && threadId !== 'new'
    //   ?
    `/v1/agendas/${agendaId}/threads/${threadId}/messages`;
  // : null;

  // if the agendaId starts with the letters "RM" we need a different url
  if (agendaId?.startsWith('RM')) {
    url = `/v1/rooms/${agendaId}/threads/${threadId}/messages`;
  }
  console.log(url);

  const {
    data: message,
    error,
    isLoading,
    isValidating,
    mutate,
  } = useSWR<Groupthink.SuccessfulResponseContent<'message.index'>>(url, fetcher, {
    keepPreviousData: true,
  });

  useRealtimeCollection?.(
    'MessageSent',
    threadId ? `threads.${threadId}.messages` : null,
    mutate,
    'create',
    url
  );

  const sendMessage = <RouteName = 'message.send'>(
    content: string,
    {
      setErrors,
      setIsSending,
      onSuccess,
    }: Omit<Groupthink.BaseOperationOptions<RouteName>, 'payload'> & {
      setIsSending?: (isSending: boolean) => void;
    }
  ) =>
    apiRequest<RouteName>(url, mutate, 'POST', {
      setErrors,
      setLoading: setIsSending,
      payload: { content },
      onSuccess,
    });

  return {
    messages: message?.data,
    isLoading,
    isValidating,
    isError: error,
    sendMessage,
    mutate,
  };
};
