import { DependencyList, EffectCallback, useEffect } from 'react';
import { throttle } from 'lodash';

export const useDebouncedEffect = (
  effect: EffectCallback,
  deps?: DependencyList,
  delay?: number | undefined
) => {
  useEffect(() => {
    const handler = setTimeout(() => effect(), delay);

    return () => clearTimeout(handler);
  }, [...(deps || []), delay]);
};

export const useThrottledEffect = (
  effect: EffectCallback,
  deps?: DependencyList,
  delay?: number | undefined
) => {
  useEffect(() => {
    return throttle(() => {
      effect();
      return undefined;
    }, delay);
  }, [...(deps || []), delay]);
};
